import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageName } from "../Actions/globalActions";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Checkbox,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { getUsers } from "../Actions/usersActions";
import { getCompanieNameByID } from "../Utils/utils";

export default function Users(props) {
  const { networkController } = props;
  const dispatch = useDispatch();
  const users = useSelector((state) => state.utilisateurs.users);
  const companies = useSelector((state) => state.companies.companies);
  const request = useSelector((state) => state.utilisateurs.request);

  const [columns, setColumns] = useState([
    "Username",
    "Email",
    "Prénom",
    "Nom",
    {
      name: "Admin",
      options: {
        customBodyRender: (value) => <Checkbox checked={value} disabled />,
      },
    },
    "Company",
    "Partner",
    "Application",
    "Parameter",
  ]);

  const [tableData, setTableData] = useState([]);

  const options = {
    filterType: "multiselect",
    responsive: "scrollFullHeight",
    print: false,
  };

  //REquête à effectuer au chargement de la page
  useEffect(() => {
    //Changement du nom de la page
    dispatch(setPageName("Utilisateurs"));

    //Récupération des users
    dispatch(getUsers(networkController));
  }, [dispatch, networkController]);

  //Lorsque nos données de users changent on re créé nos données pour le tabelau
  useEffect(() => {
    var newData = [];
    users.forEach((user) => {
      newData.push([
        user.username,
        user.email,
        user.firstName,
        user.lastName,
        user.isAdmin,
        getCompanieNameByID(user.companyId, companies),
        "",
        user.appId,
        JSON.stringify(user.parameter),
      ]);
    });

    setTableData(newData);
  }, [users, companies]);

  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <MUIDataTable
          title={
            <Typography variant="title" color="textSecondary">
              Liste des utilisateurs{" "}
              {request && (
                <CircularProgress
                  size={24}
                  style={{
                    marginLeft: 15,
                    position: "relative",
                    top: 4,
                  }}
                />
              )}
            </Typography>
          }
          data={tableData}
          columns={columns}
          options={options}
          style={{ color: "#414040" }}
        />
      </Grid>
    </Grid>
  );
}
