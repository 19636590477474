import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../Styles/dashboard";
import { useDispatch } from "react-redux";
import { getCompanies } from "../Actions/companiesActions";
import { getIslets } from "../Actions/isletActions";

export default function Dashboard(props) {
  const { networkController } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies(networkController));
    dispatch(getIslets(networkController));
  }, [dispatch, networkController]);

  return (
    <Grid container>
      <Grid item xs={12} classes={{ root: classes.fullHeight }}>
        toto
      </Grid>
    </Grid>
  );
}
