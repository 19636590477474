import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = "16.666667%"; //(100% / 12) * 2

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  drawerHeader: {
    width: "100%",
    color: theme.palette.text.secondary,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  gridButton: {
    maxHeight: "100%",
  },
  Button: {
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
    padding: "10px",
    color: "#414040",
  },
}));

export default useStyles;
