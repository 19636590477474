import requestType from "../Utils/requestType";
import localforage from "localforage";
import { getBennes } from "./vehiclesActions";

//import { getIslets } from "./plotsActions";

export const LOGIN = requestType("LOGIN");
export const login = (
  networkController,
  enqueueSnackbar,
  username,
  password,
  tenant,
  saveSession,
  version
) => async (dispatch) => {
  dispatch({ type: LOGIN.Request });

  //Vérification des champs du tenant
  if (!username || !password || !tenant) {
    //Problème avec les champs d'authentifications
    enqueueSnackbar("Identifiant incorrect", {
      variant: "error",
      key: "invalid credential",
    });
    dispatch({ type: LOGIN.Failure });
  }

  if (!networkController) {
    //Problème avec le networkController
    enqueueSnackbar("Erreur inconnue.", {
      variant: "error",
      key: "invalid credential",
    });
    dispatch({ type: LOGIN.Failure });
  }

  const userInfo = await networkController
    .login({
      username: username,
      password: password,
      tenant: tenant,
    })
    .then(async (resUser) => {
      //Wrapp le nom du tenant dans le userInfo
      resUser.user.companyName = tenant;

      //Si nous avons réussi à nous connecter; on met à jour notre localforage
      await localforage.getItem("authentification").then(async (res) => {
        if (res === null) {
          //La clé n'existe pas, nous venons la créer
          await localforage.setItem("authentification", password);
        } else {
          //SI jamais la clé existe nous vérifions qu'elle soit bonne, sinon nous la mettons à jour
          if (res !== resUser.hash) {
            await localforage.setItem("authentification", password);
          }
        }
      });

      //On viens ensuite enregistrer nos params d'utilisateur
      await localforage.setItem("userInfo", resUser);

      return resUser;
    })
    .catch(async (error) => {
      //Erreur avec l'authentification, on essaye de se connecter en local
      const isLogged = await localforage
        .getItem("authentification")
        .then((res) => {
          if (res === null) {
            //La clé n'existe pas
            return false;
          } else {
            //On vérifie que la clé soit valide
            if (res === password) return true;
            return false;
          }
        });

      if (isLogged) {
        //Connexion local reussi, on va chercher les params du user et on le retourne
        return await localforage.getItem("userInfo");
      } else {
        //Echec de l'authentification en local
        return null;
      }
    });

  if (userInfo === null) {
    dispatch({ type: LOGIN.Failure });
    enqueueSnackbar("Impossible de contacter le serveur ", {
      variant: "error",
      key: "invalid credential",
    });
    return;
  }

  //On enregistre le saveSession
  localforage.setItem("saveSession", saveSession);

  dispatch({
    type: LOGIN.Success,
    companyParameters: {},
    userInfo: userInfo.user,
  });

  //Une fois que nous somme connecté, nous allons récuperer nos plots
  //await dispatch(getIslets(networkController));

  //Nous récupérons ensuite notre liste de benne
  dispatch(getBennes(networkController, enqueueSnackbar));
  //Nous récupérons
};

export const LOG_OUT = "LOG_OUT";
export const logout = () => async (dispatch) => {
  dispatch({ type: LOG_OUT });
};
