import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#dadad9",
    boxShadow: "none",
    color: theme.palette.text.secondary,
  },
  title: {
    flexGrow: 1,
  },
}));

export default useStyles;
