import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";

export default function Bennes(props) {
  //Store
  const requestInProgress = useSelector(
    (state) => state.vehicles.requestInProgress
  );
  const bennes = useSelector((state) => state.vehicles.bennes);

  //Etat local
  const [columns, setColumns] = useState([
    "id",
    "Name",
    "Delivery",
    "Date",
    "Latitude",
    "Longitude",
    "Statut",
    "Camion",
  ]);

  const options = {
    filterType: "multiselect",
    responsive: "scrollFullHeight",
    print: false,
    expandableRows: true,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowsExpand: (curExpanded, allExpanded) =>
      console.log(curExpanded, allExpanded),
  };

  const tableData = bennes.map((benne) => {
    return [
      benne.id,
      benne.name,
      benne.delivery[0] === null ? "-" : benne.delivery[0],
      benne.last_equipment_location ? benne.last_equipment_location.date : "-",
      benne.last_equipment_location
        ? benne.last_equipment_location.latitude
        : "-",
      benne.last_equipment_location
        ? benne.last_equipment_location.longitude
        : "-",
      benne.status ? benne.status.name : "-",
      benne.truck_id ? benne.truck_id : "-",
    ];
  });

  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        {
          <MUIDataTable
            title={
              <Typography color="textSecondary">
                Liste des bennes{" "}
                {requestInProgress && (
                  <CircularProgress
                    size={24}
                    style={{
                      marginLeft: 15,
                      position: "relative",
                      top: 4,
                    }}
                  />
                )}
              </Typography>
            }
            data={tableData}
            columns={columns}
            options={options}
            style={{ color: "#414040" }}
          />
        }
      </Grid>
    </Grid>
  );
}
