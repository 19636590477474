import React from "react";
import ReactDOM from "react-dom";
import "./Styles/index.css";
import App from "./Hooks/App";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import CustomTheme from "./Styles/theme";
import RequestControlleur from "./API/Controlleur";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";

//Création de notre objet Controlleur qui va nous servir à comuniquer avec le serveur
const networkController = new RequestControlleur();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={CustomTheme}>
        <SnackbarProvider preventDuplicate>
          <App networkController={networkController} />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();