import requestType from "../Utils/requestType";

export const GET_COMPANIES = requestType("GET_COMPANIES");
export const getCompanies = (networkController) => async (dispatch) => {
  dispatch({ type: GET_COMPANIES.Request });

  networkController
    .fetchURL("/companies")
    .then((companies) => {
      dispatch({ type: GET_COMPANIES.Success, companies });
    })
    .catch((err) => {
      dispatch({ type: GET_COMPANIES.Failure });
    });
};
