// Fichier de reducers Redux qui gère les états des variables de connexion
import * as Actions from "../Actions/usersActions.js";
import { LOG_OUT } from "../Actions/loginFormActions";

export default function UtilisateursReducer(
  state = {
    users: [],
    request: false,
  },
  action
) {
  switch (action.type) {
    case Actions.GET_USERS.Request: {
      return {
        ...state,
        request: true,
      };
    }
    case Actions.GET_USERS.Success: {
      return {
        ...state,
        request: false,
        users: action.users,
      };
    }
    case Actions.GET_USERS.Failure: {
      return {
        ...state,
        request: false,
      };
    }
    case LOG_OUT: {
      return {
        users: [],
        request: false,
      };
    }
    default: {
      return state;
    }
  }
}
