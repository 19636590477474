export const SET_PAGE_NAME = "SET_PAGE_NAME";
export const setPageName = (pageName) => async (dispatch) => {
  dispatch({ type: SET_PAGE_NAME, pageName });
};

export const SET_COORDINATES = "SET_COORDINATES";
export const setCoordinates = (Geolocation) => async (dispatch) => {
  const coordinates = {
    latitude: Geolocation.coords.latitude,
    longitude: Geolocation.coords.longitude,
    atitude: Geolocation.coords.atitude,
    speed: Geolocation.coords.speed,
    accuracy: Geolocation.coords.accuracy,
    heading: Geolocation.coords.accuracy,
    timestamp: Geolocation.timestamp,
  };

  dispatch({ type: SET_COORDINATES, coordinates });
};
