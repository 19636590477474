// Fichier de reducers Redux qui gère les états des variables de connexion
import * as Actions from "../Actions/companiesActions.js";
import { LOG_OUT } from "../Actions/loginFormActions";

export default function CompanyReducer(
  state = {
    companies: [],
    request: false,
  },
  action
) {
  switch (action.type) {
    case Actions.GET_COMPANIES.Request: {
      return {
        ...state,
        request: true,
      };
    }
    case Actions.GET_COMPANIES.Success: {
      return {
        ...state,
        request: false,
        companies: action.companies,
      };
    }
    case Actions.GET_COMPANIES.Failure: {
      return {
        ...state,
        request: false,
      };
    }
    case LOG_OUT: {
      return {
        companies: [],
        request: false,
      };
    }
    default: {
      return state;
    }
  }
}
