export const getCompanieNameByID = (ID, Companies) => {
  const comp = Companies.find((companie) => companie.id === ID);

  return comp !== undefined ? comp.name : ID;
};

export const calculateSurfaceTotal = (plots) => {
  var surface = 0;

  plots.forEach((plot) => {
    surface += plot.area;
  });

  return surface / 10000;
};
