import React, { useState } from "react";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
  Paper,
} from "@material-ui/core";
import {
  Visibility,
  VisibilityOff,
  LockOutlined,
  AccountCircle,
  Business,
  Send,
} from "@material-ui/icons";
import { useForm } from "react-hook-form";
import localforage from "localforage";
import ReactReader from "../Utils/reactReader";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../Actions/loginFormActions";
import { useStyles } from "../Styles/appLogin";

export default function AppLogin(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const isLoggedIn = useSelector((state) => state.loginForm.isLoggedIn);
  const { networkController } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [connectionParams, setConnectionParams] = useState({
    username: "",
    password: "",
    tenant: "",
    saveSession: false,
    isLoading: true,
  });

  const { register, handleSubmit, errors } = useForm();

  //Lorsque nous somme connecté, on se redirige vers le page du menu
  React.useEffect(() => {
    if (isLoggedIn) history.replace("/dashboard");
  }, [isLoggedIn, history]);

  //Lors du premier chargement de l'écran on récupére le isSavedSession et le user info si besoin
  React.useEffect(() => {
    async function getSS() {
      const SS = await localforage.getItem("saveSession");

      //Si nous avons un saveSession d'enregistrer et qu'il vaut true, on récupère aussi les info du user
      if (SS !== null && SS) {
        const userInfo = await localforage.getItem("userInfo");

        if (userInfo !== null) {
          //Si un user info est récuperé on récupère les params de connection(username, tenant, hash, savedSession)
          setConnectionParams({
            username: userInfo.user.username,
            password: "quest",
            tenant: userInfo.user.companyName,
            saveSession: SS,
            isLoading: false,
          });
        } else {
          setConnectionParams({
            username: "",
            password: "",
            tenant: "",
            saveSession: false,
            isLoading: false,
          });
        }
      } else {
        setConnectionParams({
          username: "",
          password: "",
          tenant: "",
          saveSession: false,
          isLoading: false,
        });
      }
    }

    getSS();
  }, []);

  const onSubmit = (data) => {
    //On se connecte
    dispatch(
      login(
        networkController,
        enqueueSnackbar,
        data.username,
        data.password,
        data.tenant,
        data.saveSession
      )
    );
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="stretch"
      classes={{ root: classes.fullHeight }}
    >
      {/* Image background*/}
      <div
        style={{
          zIndex: "1",
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          backgroundImage: `url(${require("../Pictures/BackgroundSpace.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundOrigin: "border-box",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      {/* On affiche une auth classique */}
      {
        /* CONNECTION CLASSIQUE*/
        <Grid item xs={12} style={{ zIndex: 10 }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            classes={{ root: classes.fullHeight }}
          >
            <Grid
              item
              xs={10}
              md={4}
              classes={{ root: classes.gridClassiqueLogin }}
            >
              {!connectionParams.isLoading && (
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={classes.formRoot}
                >
                  {/*  USERNAME  */}
                  <TextField
                    inputRef={register({ required: "Champ obligatoire." })}
                    defaultValue={connectionParams.username}
                    helperText={errors.username && errors.username.message}
                    error={errors.username}
                    fullWidth
                    autoComplete="section-quest username"
                    label={"Identifiant"}
                    id="username"
                    name="username"
                    margin="normal"
                    inputProps={{
                      className: classes.darkText,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          classes={{ root: classes.darkText }}
                        >
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    //onChange={(event) => dispatch(Actions.setUserId(event.target.value))}
                  />

                  {/*  PASSWORD  */}
                  <TextField
                    helperText={errors.password && errors.password.message}
                    error={errors.password}
                    fullWidth
                    label={"Mot de passe"}
                    inputRef={register({ required: "Champ obligatoire." })}
                    defaultValue={connectionParams.password}
                    autoComplete="section-quest current-password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    margin="normal"
                    inputProps={{
                      className: classes.darkText,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          classes={{ root: classes.darkText }}
                        >
                          <LockOutlined />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Afficher/Cacher le mot de passe"
                            onClick={(e) => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    //onChange={(event) => dispatch(Actions.setUserPassword(event.target.value)) }
                  />

                  {/* TENANT */}
                  <TextField
                    helperText={errors.tenant && errors.tenant.message}
                    error={errors.tenant}
                    inputRef={register({ required: "Champ obligatoire." })}
                    defaultValue={connectionParams.tenant}
                    fullWidth
                    autoComplete="section-quest organization"
                    label={"Compte"}
                    id="tenant"
                    name="tenant"
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          classes={{ root: classes.darkText }}
                        >
                          <Business />
                        </InputAdornment>
                      ),
                    }}
                    //onChange={(event) => dispatch( Actions.setUserTenant(event.target.value.toLocaleLowerCase()))}
                    inputProps={{
                      style: { textTransform: "lowercase" },
                      className: classes.darkText,
                    }}
                    classes={{ root: classes.darkText }}
                  />

                  {/*  REMEMBER ME  */}
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked={connectionParams.saveSession} />
                    }
                    label={"Se souvenir de moi"}
                    inputRef={register}
                    name="saveSession"
                    classes={{
                      label: classes.darkText,
                      root: classes.fullWidth,
                    }}
                    //onChange={(event) => {dispatch(Actions.setSaveSession(event.target.checked));}}
                  />

                  {/* SUBMIT */}
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        type="submit"
                        classes={{ root: classes.submitButton }}
                      >
                        <Typography color="textPrimary">Connection</Typography>
                        <Send className={classes.submitIcon} />
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
              {/* set up the dev tool */}
              {/*<DevTool control={control} />*/}
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  );
}
