import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    text: {
      primary: "#dadad9",
      secondary: "#414040",
    },
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        color: "#414040",
      },
    },
    MUIDataTableBodyCell: {
      root: {
        color: "#414040",
      },
    },
    MUIDataTableToolbarSelect: {
      title: {
        color: "#414040",
      },
    },
    MUIDataTablePagination: {
      root: {
        color: "#414040",
      },
    },
  },
});
