import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import useStyles from "../Styles/appHeader";

export default function AppHeader(props) {
  const pageName = useSelector((state) => state.global.pageName);
  const classes = useStyles();
  return (
    <AppBar position="relative" classes={{ root: classes.appBar }}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {pageName}
        </Typography>
        <Button color="inherit">Login</Button>
      </Toolbar>
    </AppBar>
  );
}
