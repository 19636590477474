import React from "react";
import useStyles from "../Styles/menuDrawer";
import {
  Drawer,
  Divider,
  Grid,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Link } from "react-router-dom";

export default function MenuDrawer(props) {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Divider />
      <Toolbar>
        <Typography align="center" classes={{ root: classes.drawerHeader }}>
          QI Admin
        </Typography>
      </Toolbar>

      <Grid container justify="center" classes={{ root: classes.gridButton }}>
        <Grid item xs={10}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="flex-start">
            <Grid item xs={12}>
              <Link to="/users">
                <Button
                  classes={{ root: classes.Button }}
                  startIcon={<AccountCircleIcon />}
                >
                  Utilisateurs
                </Button>
              </Link>
            </Grid>

            <Grid item xs={12}>
              <Link to="/islets">
                <Button
                  classes={{ root: classes.Button }}
                  startIcon={<AccountCircleIcon />}
                >
                  Ilots
                </Button>
              </Link>
            </Grid>

            <Grid item xs={12}>
              <Link to="/bennes">
                <Button
                  classes={{ root: classes.Button }}
                  startIcon={<AccountCircleIcon />}
                >
                  Bennes
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
}
