import requestType from "../Utils/requestType";

export const GET_BENNES = requestType("GET_BENNES");
export const getBennes = (networkController, enqueueSnackbar) => async (
  dispatch
) => {
  dispatch({ type: GET_BENNES.Request });

  await networkController
    .fetchURL("/equipments/bennes")
    .then((bennes) => {
      console.log(bennes);
      dispatch({ type: GET_BENNES.Success, bennes });
    })
    .catch((err) => {
      dispatch({ type: GET_BENNES.Failure });
      enqueueSnackbar("Impossible de récuperer les bennes", {
        variant: "error",
        key: "bennesError",
      });
      console.error(err);
    });
};

export const GET_MACHINES = requestType("GET_MACHINES");

export const GET_TRUCKS = requestType("GET_TRUCKS");
