import Constants from "../Constants";
import localforage from "localforage";
export default class RequestControlleur {
  constructor() {
    //Inititalisation des variable par defaut
    //Variable contenant notre token d'identification
    this.TOKEN = "";
    //Création du protocole par défaut
    this.PROTOCOLE = "GET";
    //Définition du HOST
    this.HOST = Constants.HOST;
  }

  set token(token) {
    this.TOKEN = token;
  }

  login({ username, password, tenant }) {
    let URL = `${this.HOST}/auth/login`;
    let headers = new Headers({ "Content-Type": "application/json" });

    return new Promise((resolve, reject) => {
      fetch(URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          username: username,
          password: password,
          tenant: tenant,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          this.TOKEN = json.token;
          resolve(json);
        })
        .catch(async (err) => {
          //Echec de l'appel Réseau, on regarde si on peut se connecter en local
          this._errorCatcher(err);
          reject(err);
        });
    });
  }

  fetchSync(URL, PROTOCOLE = this.PROTOCOLE, BODY = {}) {
    //Création du header qui contient notre token d'identification
    const xToken = new Headers({ "X-Token": this.TOKEN });

    //Construction du endPoint
    //const endPoint = `${this.HOST}${URL}`;

    //Construction de nos options
    const options = {
      method: PROTOCOLE,
      //headers: xToken,
    };

    //Si nous avons un body, nous le rajoutons aux options
    if (Object.keys(BODY).length > 0) {
      options.body = JSON.stringify(BODY);
    }

    //Construction de notre Params
    const params = {
      url: URL,
      xToken: this.TOKEN,
      fetchOptions: options,
    };
    //On enregistre nos paramètres le localForage pour une utilisation pas le SW
    return new Promise((resolve, reject) => {
      localforage
        .setItem("backgroundSync", params)
        .then(async () => {
          async function nextFunction(res, watchDog) {
            //Function appellé lors de la reponse
            if (res.ok) {
              //Nous avons bien reçu la réponse on vide la réponse en localForage
              localforage.setItem("backgrounSyncResponse", {}).then(() => {
                clearInterval(watchDog);
                resolve(res.body);
              });
            } else {
              //Nous avons bien reçu la réponse on vide la réponse en localForage
              localforage.setItem("backgrounSyncResponse", {}).then(() => {
                clearInterval(watchDog);
                reject(res.error);
              });
            }
          }

          //une fois que nous avons enregistrer ls parmars de la background Sync nous appelons l'event du SW
          const SW = await navigator.serviceWorker.ready;

          //Création du watchdog
          const watchDog = setInterval(async function () {
            localforage.getItem("backgroundSyncResponse").then((response) => {
              if (response !== {}) {
                nextFunction(response, watchDog);
              }
            });
          }, 1000);

          //Création d'une promise qui va effectuer notre appel dans le backgroundSync
          SW.sync
            .register("backgroundSync")
            .then(() => console.log("registered"))
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    });
  }

  fetchURL(URL, PROTOCOLE = this.PROTOCOLE, BODY = {}) {
    //Création du header qui contient notre token d'identification
    const xToken = new Headers({ "X-Token": this.TOKEN });

    //Construction du endPoint
    const endPoint = `${this.HOST}${URL}`;

    //Construction de nos options
    const options = {
      method: PROTOCOLE,
      headers: xToken,
    };

    //Si nous avons un body, nous le rajoutons aux options
    if (Object.keys(BODY).length > 0) {
      options.body = JSON.stringify(BODY);
    }

    //Création d'une promise qui va effectuer notre appel
    return new Promise((resolve, reject) => {
      fetch(endPoint, options)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          this._errorCatcher(err);
          reject(err);
        });
    });
  }

  _errorCatcher(err) {
    console.error(err);
  }
}
