// Fichier Redux qui regroupe les reducers
import loginForm from "./loginFormReducer";
import global from "./globalReducer";
import utilisateurs from "./UtilisateursReducer";
import companies from "./companyReducer";
import islets from "./IsletReducer.js";
import vehicles from "./vehiclesReducer";

import { combineReducers } from "redux";

export default combineReducers({
  loginForm,
  global,
  utilisateurs,
  companies,
  islets,
  vehicles,
});
