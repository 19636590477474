import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageName } from "../Actions/globalActions";
import MUIDataTable from "mui-datatables";
import L from "leaflet";
import MapRoundedIcon from "@material-ui/icons/MapRounded";
import {
  Grid,
  Checkbox,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";
import { getUsers } from "../Actions/usersActions";
import { getCompanieNameByID, calculateSurfaceTotal } from "../Utils/utils";
import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

export default function Islets(props) {
  const { networkController } = props;
  const dispatch = useDispatch();
  const islets = useSelector((state) => state.islets.islets);
  const companies = useSelector((state) => state.companies.companies);
  const request = useSelector((state) => state.islets.request);
  const [openModal, setopenModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({
    title: "",
  });

  const [columns, setColumns] = useState([
    "Name",
    "Companie",
    "Contrat",
    {
      name: "Cloturé",
      options: {
        customBodyRender: (value) => <Checkbox checked={value} disabled />,
      },
    },
    "Sous-Parcelle",
    "Surface Total",
    {
      name: "Sous-Parcelle-Object",
      options: {
        display: "false",
      },
    },
  ]);

  const [tableData, setTableData] = useState([]);

  const handleClickToShow = (data) => {
    const leafletData = {
      title: data.title,
      center: {
        lat:
          data.northWest.latitude -
          (data.northWest.latitude - data.southEast.latitude),
        lng:
          data.southEast.longitude -
          (data.southEast.longitude - data.northWest.longitude),
      },
      vertices: data.vertices,
    };
    setModalData(leafletData);
    setopenModal(true);
  };

  const options = {
    filterType: "multiselect",
    responsive: "scrollFullHeight",
    print: false,
    expandableRows: true,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const _plot = rowData[6];
      const _columns = [
        "Name",
        "Companie",
        "Surface",
        "Dernière OP",
        {
          name: "Coords",
          options: { display: "false" },
        },
        {
          name: "Options",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              console.log(tableMeta);
              return (
                <IconButton
                  aria-label="visualize"
                  onClick={(e) =>
                    handleClickToShow({
                      name: tableMeta.rowData[0],
                      vertices: value,
                      northWest: tableMeta.rowData[4][0],
                      southEast: tableMeta.rowData[4][1],
                    })
                  }
                >
                  <MapRoundedIcon />
                </IconButton>
              );
            },
          },
        },
      ];
      const _data = _plot.map((plot) => [
        plot.name,
        getCompanieNameByID(plot.companyId, companies),
        (plot.area / 10000).toFixed(2),
        plot.lastOperation,
        [plot.northWest, plot.southEast],
        plot.vertices,
      ]);
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <MUIDataTable title={rowData[0]} columns={_columns} data={_data} />
          </TableCell>
        </TableRow>
      );
    },
    onRowsExpand: (curExpanded, allExpanded) =>
      console.log(curExpanded, allExpanded),
  };

  //REquête à effectuer au chargement de la page
  useEffect(() => {
    //Changement du nom de la page
    dispatch(setPageName("Islets"));

    //Récupération des users
    dispatch(getUsers(networkController));
  }, [dispatch, networkController]);

  //Lorsque nos données de users changent on re créé nos données pour le tabelau
  useEffect(() => {
    var newData = [];
    islets.forEach((islet) => {
      newData.push([
        islet.name,
        getCompanieNameByID(islet.companyId, companies),
        islet.contract,
        islet.is_done,
        islet.plots.length,
        calculateSurfaceTotal(islet.plots).toFixed(2),
        islet.plots,
      ]);
    });

    setTableData(newData);
  }, [islets, companies]);

  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <MUIDataTable
          title={
            <Typography variant="title" color="textSecondary">
              Liste des islets{" "}
              {request && (
                <CircularProgress
                  size={24}
                  style={{
                    marginLeft: 15,
                    position: "relative",
                    top: 4,
                  }}
                />
              )}
            </Typography>
          }
          data={tableData}
          columns={columns}
          options={options}
          style={{ color: "#414040" }}
        />
      </Grid>

      <Dialog
        open={openModal}
        keepMounted
        fullScreen
        onClose={(e) => setopenModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>{modalData.title}</DialogTitle>
        <DialogContent>
          <Map center={modalData.center} zoom={10}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </Map>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
