import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  bodyContainer: {
    height: "calc(100% - 72px)", //72px = hauteur du header + footer
    overflow: "auto",
  },
  footerContainer: {
    zIndex: 10,
  },
  OfflineAvatar: {
    position: "fixed",
    right: "5%",
    bottom: "calc(31px + 2%)", //31px = hauteur du footer
    backgroundColor: "#C4140D",
    zIndex: 10000,
  },
  gridBody: {
    backgroundColor: "#DADAD9",
    height: "100%",
    overflow: "auto",
  },
}));

export default useStyles;
