import requestType from "../Utils/requestType";

export const GET_ISLETS = requestType("GET_ISLETS");
export const getIslets = (networkController) => async (dispatch) => {
  dispatch({ type: GET_ISLETS.Request });

  networkController
    .fetchURL("/islets")
    .then((islets) => {
      //Trie par ordre alphaetique

      islets.sort((isletA, isletB) => {
        if (isletA.name < isletB.name) {
          return -1;
        }
        if (isletB.name < isletA.name) {
          return 1;
        }
        return 0;
      });
      dispatch({ type: GET_ISLETS.Success, islets });
    })
    .catch((err) => {
      dispatch({ type: GET_ISLETS.Failure });
    });
};
