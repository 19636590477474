import requestType from "../Utils/requestType";

export const GET_USERS = requestType("GET_USERS");
export const getUsers = (networkController) => async (dispatch) => {
  dispatch({ type: GET_USERS.Request });

  networkController
    .fetchURL("/users")
    .then((users) => {
      dispatch({ type: GET_USERS.Success, users });
    })
    .catch((err) => {
      dispatch({ type: GET_USERS.Failure });
    });
};
