// Fichier de reducers Redux qui gère les états des variables de connexion
import * as Actions from "../Actions/globalActions.js";
import { LOG_OUT } from "../Actions/loginFormActions";

export default function globalReducer(
  state = {
    pageName: "Dashboard",
    coordinates: {},
  },
  action
) {
  switch (action.type) {
    case Actions.SET_PAGE_NAME: {
      return {
        ...state,
        pageName: action.pageName,
      };
    }
    case Actions.SET_COORDINATES: {
      return {
        ...state,
        coordinates: action.coordinates,
      };
    }
    case LOG_OUT: {
      return {
        pageName: "Georoad",
        coordinates: {},
      };
    }

    default: {
      return state;
    }
  }
}
