// Fichier de reducers Redux qui gère les états des variables de connexion
import * as Actions from "../Actions/vehiclesActions.js";
import { LOG_OUT } from "../Actions/loginFormActions";

export default function VehiculeReducer(
  state = {
    bennes: [],
    trucks: [],
    machines: [],
    requestInProgress: false,
  },
  action
) {
  switch (action.type) {
    case Actions.GET_BENNES.Success: {
      return {
        ...state,
        bennes: action.bennes,
        requestInProgress: false,
      };
    }
    case Actions.GET_BENNES.Request: {
      return {
        ...state,
        requestInProgress: true,
      };
    }
    case Actions.GET_BENNES.Failure: {
      return {
        ...state,
        requestInProgress: false,
      };
    }
    case Actions.GET_TRUCKS.Success: {
      return {
        ...state,
        trucks: action.trucks,
      };
    }
    case Actions.GET_MACHINES.Failure: {
      return {
        ...state,
        machines: action.machines,
      };
    }
    case LOG_OUT: {
      return {
        bennes: [],
        trucks: [],
        machines: [],
        requestInProgress: false,
      };
    }
    default: {
      return state;
    }
  }
}
