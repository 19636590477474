import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  QRcodeGrid: {
    textAlign: "center",
  },
  darkText: {
    color: "#414040",
  },
  gridClassiqueLogin: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "9px",
    padding: "10px",
    boxShadow: "0px 0px 10px 0px",
  },
  formRoot: {
    padding: "10px",
  },
  submitButton: {
    backgroundColor: "#C4161B",
  },
  HR: {
    backgroundColor: "#C4161B",
    color: "#C4161B",
    height: "1px",
  },
  submitIcon: {
    paddingLeft: "10px",
    color: "#dadad9",
  },
}));
