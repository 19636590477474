// Fichier de reducers Redux qui gère les états des variables de connexion
import * as Actions from "../Actions/isletActions.js";
import { LOG_OUT } from "../Actions/loginFormActions";

export default function isletReducer(
  state = {
    islets: [],
    request: false,
    error: false,
  },
  action
) {
  switch (action.type) {
    case Actions.GET_ISLETS.Request: {
      return {
        ...state,
        request: true,
      };
    }
    case Actions.GET_ISLETS.Success: {
      return {
        ...state,
        islets: action.islets,
        request: false,
        error: false,
      };
    }
    case Actions.GET_ISLETS.Failure: {
      return {
        ...state,
        request: false,
        error: true,
      };
    }
    case LOG_OUT: {
      return {
        islets: [],
        request: false,
        error: false,
      };
    }

    default: {
      return state;
    }
  }
}
