// Fichier de reducers Redux qui gère les états des variables de connexion
import * as Actions from "../Actions/loginFormActions.js";

export default function loginFormReducer(
  state = {
    isLoginInProgress: false,
    isLoggedIn: false,
    error: false,
    userInfo: {},
    companyParameters: {},
  },
  action
) {
  switch (action.type) {
    case Actions.LOGIN.Request: {
      return {
        ...state,
        isLoginInProgress: true,
      };
    }
    case Actions.LOGIN.Failure: {
      return {
        ...state,
        isLoginInProgress: false,
        error: true,
      };
    }
    case Actions.LOGIN.Success: {
      return {
        ...state,
        isLoginInProgress: false,
        error: false,
        isLoggedIn: true,
        userInfo: action.userInfo,
        companyParameters: action.companyParameters,
      };
    }
    case Actions.LOG_OUT: {
      return {
        username: localStorage.getItem("username") || "",
        password: localStorage.getItem("password") || "",
        tenant: localStorage.getItem("tenant") || "",
        saveSession: localStorage.getItem("saveSession") || "",
        isLoginInProgress: false,
        isLoggedIn: false,
        error: false,
        userInfo: {},
        companyParameters: {},
      };
    }
    default: {
      return state;
    }
  }
}
