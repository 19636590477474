import React from "react";
import useStyles from "../Styles/app";
import Dashboard from "./Dashboard";
import MenuDrawer from "./MenuDrawer";
import AppHeader from "./AppHeader";
import Users from "./Users";
import Islets from "./Islets";
import Bennes from "./Bennes";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App(props) {
  const { networkController } = props;
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.loginForm.isLoggedIn);
  return (
    <Router>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        classes={{ root: classes.fullHeight }}
      >
        <Grid
          item
          xs={2}
          classes={{ root: classes.fullHeight }}
          style={!isLoggedIn ? { display: "none" } : {}}
        >
          {/*Menu drawer*/}
          {isLoggedIn && <MenuDrawer />}
        </Grid>

        <Grid
          item
          xs={isLoggedIn ? 10 : 12}
          classes={{ root: classes.gridBody }}
        >
          {/* App Bar*/}
          {isLoggedIn && <AppHeader />}

          {/*Liste des routes*/}
          <Switch>
            <PrivateRoute path="/users">
              <Users networkController={networkController} />
            </PrivateRoute>
            <PrivateRoute path="/islets">
              <Islets networkController={networkController} />
            </PrivateRoute>
            <PrivateRoute path="/dashboard">
              <Dashboard networkController={networkController} />
            </PrivateRoute>
            <PrivateRoute path="/bennes">
              <Bennes networkController={networkController} />
            </PrivateRoute>
            <Route path="/">
              <Login networkController={networkController} />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </Router>
  );
}
